import React from 'react'
import { Link } from 'react-router-dom'
import supportTeamBanner from '../../images/practice/management/management-07.jpeg'
import samplePortrait from '../../images/practice/management/director-of-finance/dof-03.jpeg'
import AESLPeopleNav from '../../components/organisms/navs/AESL_PeopleNav'
import AESLPageFooter from '../../components/organisms/footers/AESLPageFooter'
import SectionFooter from '../../components/organisms/footers/SectionFooter'
import { supportTeam } from '../../data/aeslPeopleInfo'
import PeoplePortraitCard from '../../components/organisms/cards/PeoplePortraitCard'

const fetchSupportTeam = () => {
    return supportTeam;
};
const SupportTeam = () => {
    // Data Fetch


const supportTeamData = fetchSupportTeam();
        console.log(supportTeam);
    return (
        <div id=""
        className="min-vh-100 w-100"
        >
        {/* <!-- Page Header --> */}
        <header id="peope_support_team_pageHeader"
            className="grid gtc12
                "
        >
            <figure className="gc1s12"
            >
                <img src={supportTeamBanner}
                    alt=""
                    className="vh-60 cover"
                />
            </figure>
            <hgroup className="gc1s12 w-100 blue0">
                <h2 className="w-100 h-100
                    pa1-00 ph1-00 pb1-00 mb0-00
                    f3-00 ttu"
                >
                    Support Team
                </h2>
            </hgroup> 
            {/* <!-- Page Navigation -->*/}
            <AESLPeopleNav />
            {/* <!-- Page Navigation -->  */}
        </header>
        {/* <!-- Page Header --> */}

        {/* <!-- Page Main --> */}
        <main id = "support_team_pagemain"
            className="bg-white"
        >
            <div id="l_3:1_support_team" 
                className="grid gtc4 w-100"
            >
                {/* <!-- AESL Support Team Main Start --> */}
                <div id="lc_gc1s3__support_team_pageContent"
                    className="gc1s4 gc1s3-l
                        min-vh-100 ph1-00 mb1-00"
                >   
                    {/* <!-- Support Team --> */}
                    <div id="people_support_team"
                        className="grid gtc12 ggap1-00
                            
                            mb1-00"
                    >
                        {/* <!-- Support Team Description Start --> */}
                        <header id="people_support_team_description" 
                            className="gr1s1 gc2s10"
                        > 
                            <div id=""
                                className="flex ggap1-00 
                                    w-100 blue0
                                    mt6-00 mb4-00 
                                    lh-copy f1-25 tj"
                            >
                                <div>
                                    The support staff at Architectural and Engineering Services Limited provides essential project management, technical direction, and client communication support, guaranteeing seamless operations and raising client satisfaction through knowledgeable support and prompt outcomes.
                                </div>
                            </div>

                        </header>
                        {/* <!-- Support Team Description End --> */}

                        {/* <!-- Support Team Gallery Start --> */}
                        <div id="people_support_team_gallery"
                            className="gc1s12
                                blue0"
                        >
                            <ul className="gr1s1 gc1s12 
                                grid gtc1 gtc2-s gtc3-m gtc2-l gtc3-xl ggap1-00
                                w-100 
                                blue0"
                            >
                                {supportTeamData &&
                                    supportTeamData.map((supportTeam) => {
                                        return ( 
                                            <li key={supportTeam.id}
                                                className="blue0 w-100"
                                            >
                                                <PeoplePortraitCard 
                                                thumbnail = {supportTeam.thumbnail}
                                                title = {supportTeam.title}
                                                name = {supportTeam.name}
                                                profession = {supportTeam.profession}
                                                office = {supportTeam.office}
                                                department = {supportTeam.department}
                                                />
                                            </li>
                                            );
                                        }
                                    )
                                }
                            </ul>
                        </div>
                        {/* <!-- Support Team Gallery End --> */}

                        <article id="misc_gold" 
                            className="gr3s1 gc2s3
                                flex items-center justify-center
                                bg-green h6-00
                            ">
                        </article>

                        <article id="misc_blue" 
                            className="gr4s1 gc6s1
                                flex items-center justify-center
                                bg-blue0 h6-00 
                                ">
                        </article>
                    </div>
                    <SectionFooter 
                      sectionTitle ="Support Teams"
                    />
                </div>
                {/* <!-- AESL Support Team Main End -->

                <!-- AESL Support Team Aside Start --> */}
                <div id="lc_gc4s1__support_team_pageAside" 
                    className="gc4s1 
                    blue0 relative"
                >
                    <div className="flex flex-column justify-between
                        min-vh-100 
                        ph2-00 pt6-00
                        sticky top-0"
                    >
                        <ul className="mb1-00 bl bw4 b--green pl2-00 f0-75">
                            <h3 >Support Team</h3>
                            <li className="mb1-00">
                                <a href="#people_support_team_description"
                                    className="blue0 i"
                                    >
                                    AESL Support Team
                                </a>
                            </li>
                            <li className="mb1-00">
                                <a href="#people_support_team_gallery"
                                    className="blue0 i"
                                    >
                                    AESL Support Team List
                                </a>
                            </li>
                        </ul>
        
                        <div className="mb1-00" >
                            <small>Previous</small> <br/>
                            <Link to ="/people/professionals/assistant-professionals" 
                                className="mb1-00 blue0 f0-75 b"
                                >
                                Assistant Professionals
                            </Link> <br/><br/>
                            <small>Next</small> <br/>
                            <Link to ="/people/service-personnel" 
                                className="mb1-00 blue0 f0-75 b"
                            >
                                Service Personnel
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <!-- AESL Support Team Aside End --> */}
            </div>
        </main>
        {/* <!-- Page Main -->  */}
        {/* <!-- Page Footer -->   */}
        <AESLPageFooter 
            pageTitle = "Support Team"
        />
        {/* <!-- Page Footer -->  */}
    </div>
    )
}

export default SupportTeam