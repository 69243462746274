import React, { useState } from 'react'

const ProjectDetailCard = (props) => {

    const [toggle, setToggle] = useState(false);
    const {image, title, location, description } = props;

    return (
        <div className="grid items-end justify-center h-100"
        >
            <figure className="shadow-5 gc1s1 gr1s1 h-100"
            title={"Click on image for image info" }
            >
                <img src={image}
                    alt=""
                    className="h20-00 w-100 ba b--yellow bw2
                        cover" 
                /> 
            </figure>
            <hgroup 
                className={`gc1s1 gr1s1 
                    flex flex-column justify-end pa0-50  
                    ${toggle ? 'bg-yellow-transparent': ''}`
                }
            >
                {toggle ? 
                    <div className=""
                    >
                        <h3 className="blue0 f"
                        >
                            {title}
                        </h3>
                        <p className="blue0">
                            {description}
                        </p>    
                    </div> : ''
                }
                <div className={`flex items-center justify-center 
                    w1-50 h1-50 
                    ba br0-25 b b--blue0 
                    ${toggle ? 'blue0' : 'yellow'} pointer`}
                    onClick={
                        () => {setToggle(!toggle)}
                    }
                >
                    <span className="dib mb0-50 w1-50 h1-50 f1-50 b tc"
                    >
                    {toggle ? '-' : '+'}
                    </span>
                </div>
            </hgroup> 
        </div>
    )
};

export default ProjectDetailCard;
