import React from 'react'
import aesl_logo from '../../../images/_partials/logos/aesl_logo.png'
const Recents = () => {
    return (
        <div id="m_otherLinks" 
            className="gc2s1 
                gc1s2-s 
                grid gtc2 items-center justify-center ggap1-00 
                fw5" 
            aria-hidden="False"
        >
            <h2 className="gc1s2 f1-25 tc bb">Recents</h2>
            <div id="m_otherLinks--1"
                className="br1-00 gr2s1 gc1s1"  
                data-use-new="true"
            >
                <figure id="recentProjects"
                    className="grid items-end justify-center"
                >
                    <img src={aesl_logo} 
                        alt=""
                        className="w-100 br0-50 gc1s1 gr1s1"
                    />
                    <figcaption className="gc1s1 gr1s1 
                        h-100 
                        tc bg-gradient1TB gold mb1-00"
                    >
                        Project
                    </figcaption>
                </figure> 
            </div>
            <div id="m_otherLinks--2"
                className=" br1-00 gr2s1 gc2s1"  
                data-use-new="true"
            >
                <figure id="recentNews"
                    className="grid items-end justify-center"
                >
                    <img src={aesl_logo}
                        alt=""
                        className="w-100 br0-50 gc1s1 gr1s1"
                    />
                    <figcaption className="gc1s1 gr1s1 
                        h-100 mb1-00
                        tc bg-gradient1TB gold"
                    >
                        News
                    </figcaption>
                </figure> 
            </div>
        </div>
    )
}

export default Recents