export const principalConsultants = [
    {   
        id: "1",
        title : "title",
        name : "Full Name",
        slug : "full-name",
        profession : "Profession",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "Office",
        department : "Department",
    },
];

export const seniorConsultants = [
    {   id: 1,
        title : "Ing.",
        name : "Charles N. Adigvom",
        slug : "charles-n-adigvom",
        profession : "Civil Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
];

export const consultants = [
    {   id: 1,
        title : "Arc.",
        name : "Amen A. K. Gbeckor-Kove (Jnr)",
        slug : "amen-a-k-gbeckor-kove(Jnr)",
        profession : "Architect",
        
        thumbnail :"../../images/people/consultants/Consultants/Amen A. K. Gbeckor-Kove (Jnr) Volta.Oti.jpg",
        office : "AESL_Ho",
        department : "Architecture",
        cv :"view CV",
        quote :{
            text:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author: "Frank Lloyd Wright"
        }
        ,
        images : {
            image1 :"../../images/people/consultants/Consultants/Amen A. K. Gbeckor-Kove (Jnr) Volta.Oti.jpg",
            image2 : "../../images/people/consultants/Consultants/Amen A. K. Gbeckor-Kove (Jnr) Volta.Oti.jpg"
        },
    },
    {   id: 2,
        title : "Arc.",
        name : "Betsy Owusu-Antwi",
        slug : "betsy-owusu-antwi",
        profession : "Architect",
        thumbnail :"../../images/people/consultants/Consultants/Arc. Betsy Owusu-Antwi (Ashanti. Kumasi).jpg",
        office : "AESL_Kumasi",
        department : "Architecture",
        cv :"view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/people/consultants/Consultants/Arc. Betsy Owusu-Antwi (Ashanti. Kumasi).jpg",
            image2 : "../../images/people/consultants/Consultants/Arc. Betsy Owusu-Antwi (Ashanti. Kumasi).jpg"
        },
    },
    {   id: 3,
        title : "Arc.",
        name : "Naa Kwarley Quartey",
        slug : "naa-kwarley-quartey",
        profession : "Architect",
        thumbnail :"../../images/people/consultants/Consultants/Arc. Naa Kwarley Quartey (Greater Accra).jpg",
        office : "AESL_Accra",
        department : "Architecture",
        cv :"view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/people/consultants/Consultants/Arc. Naa Kwarley Quartey (Greater Accra).jpg",
            image2 : "../../images/people/consultants/Consultants/Arc. Naa Kwarley Quartey (Greater Accra).jpg"
        },
    },
    {   id: 4,
        title : "Surv.",
        name : "Annan Eric",
        slug : "annan-eric",
        profession : "Quantity Surveyor",
        thumbnail :"../../images/people/consultants/Consultants/Surv. Annan Eric (Western. western north).jpg",
        office : "AESL_Sekondi-Takoradi",
        department : "Quantity Surveying",
        cv :"view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/people/consultants/Consultants/Surv. Annan Eric (Western. western north).jpg",
            image2 : "../../images/people/consultants/Consultants/Surv. Annan Eric (Western. western north).jpg"
        },
    },
    {   id: 5,
        title : "Ing",
        name : "Frederick Owusu Addo",
        slug : "frederick-owusu-addo",
        profession : "Civil engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
        cv :"view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/placeholders/regular_images/avatar-01.png",
            image2 : "../../images/placeholders/regular_images/avatar-01.png"
        },
    },
    {   id: 6,
        title : "Ing.",
        name : "Gloria N. A. Kunutsor",
        slug : "gloria-n-a-kunutsor",
        profession : "Civil engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
        cv :"view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/placeholders/regular_images/avatar-01.png",
            image2 : "../../images/placeholders/regular_images/avatar-01.png"
        },
    },
    {   id: 7,
        title : "Ing.",
        name : "Atstiso Agbenyah",
        slug : "atstiso-agbenyah",
        profession : "Civil engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
        cv : "view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/placeholders/regular_images/avatar-01.png",
            image2 : "../../images/placeholders/regular_images/avatar-01.png"
        },
    },
    {   id: 8,
        title : "title",
        name : "Full Name",
        slug : "full-name",
        profession : "Profession",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "Office",
        department : "Department",
        cv :"view CV",
        quote : {
            text :"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, error magnam. Impedit tempore earum debitis quae vero quo. Magnam, facere. Ea, velit ut earum voluptas commodi autem explicabo blanditiis, unde veniam architecto",
            author : "Frank Lloyd Wright"
        },
        images : {
            image1 :"../../images/placeholders/regular_images/avatar-01.png",
            image2 : "../../images/placeholders/regular_images/avatar-01.png"
        },
    },
];

export const seniorProfessionals = [
    {   id: 1,
        title : "Ing.",
        name : "Emmanuel Mamphey",
        slug : "emmanuel-mamphey",
        profession : "Structural Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
];

export const professionals = [
    {   id: 1,
        title : "Ing.",
        name : "Ivy Bart-Plange",
        profession : "Structural Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 2,
        title : "Ing.",
        name : "Francis Forson",
        profession : "Structural Engineer",
        thumbnail :"../../images/people/Professionals/ING. FRANCIS FORSON...jpg",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 3,
        title : "Ing.",
        name : "Isaac Boye Kotey",
        profession : "Structural Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 4,
        title : "Mr.",
        name : "Aboor Desmond",
        profession : "Quantity Surveyor",
        thumbnail :"../../images/people/Professionals/Aboor Desmond.jpg",
        office : "AESL_Bolgatanga",
        department : "Civil Engineering",
    },
    {   id: 5,
        title : "Mr.",
        name : "Isaac Yieleh-Chireh",
        profession : "Quantity Surveyor",
        thumbnail :"../../images/people/Professionals/Isaac Yieleh-Chireh.jpg",
        office : "AESL_Ho",
        department : "Quantity Surveying",
    },
    {   id: 6,
        title : "Mr.",
        name : "Emmanuel Kwasi Okoh Larbi",
        profession : "Auditor",
        thumbnail :"../../images/people/Professionals/Emmanuel Kwasi Okoh Larbi 2.jpg",
        office : "AESL_Head Office",
        department : "Internal Audit",
    },
];

export const assistantProfessionals = [
    {   id: 1,
        title : "Mr.",
        name : "Andrew Tusoe",
        slug : "andrew-tusoe",
        profession : "Civil Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 2,
        title : "Mr.",
        name : "Kojo Ebia Takyi",
        slug : "kojo-ebia-takyi",
        profession : "Civil Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 3,
        title : "Mr.",
        name : "Michael Okyere",
        slug : "michael-okyere",
        profession : "Civil Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 4,
        title : "Mr.",
        name : "Joseph Sarpei Nunoo",
        slug : "joseph-sarpei-nunoo",
        profession : "Civil Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 5,
        title : "Mr.",
        name : "Paul E. Donto",
        profession : "Civil Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 6,
        title : "Mr.",
        name : "Evans Tagoe",
        profession : "Water Engineer",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 7,
        title : "Mr.",
        name : " Edem K. Dadadzogbor",
        profession : "Quantity Surveying",
        thumbnail :"../../images/people/assistant-professionals/Edem K. Dadadzogbor.jpg",
        office : "AESL_Ho",
        department : "Quantity Surveying",
    },
    {   id: 8,
        title : "Mr.",
        name : " Kamal Tijani",
        profession : "Quantity Surveying",
        thumbnail :"../../images/people/assistant-professionals/Kamal Tijani.jpg",
        office : "AESL_Ho",
        department : "Quantity Surveying",
    },
];

export const supportTeam = [
    {   id: 1,
        title : "Mr.",
        name : "Vincent Quartey",
        profession : "Technician",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 2,
        title : "Mr.",
        name : "Richard T. Obodai",
        profession : "Clerk of Works",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 3,
        title : "Mr.",
        name : "Hussein Sanusi",
        profession : "Building Technologist",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 4,
        title : "Ms.",
        name : "Patience Nyasembi",
        profession : "Secretary",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 5,
        title : "Mr.",
        name : "Charles M. Laryea",
        profession : "Technician",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 6,
        title : "Ms.",
        name : "Charlote N. Charway",
        profession : "Technician",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 7,
        title : "Mr.",
        name : "Emmanuel A. Adjetey",
        profession : "Technician",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 8,
        title : "Mr.",
        name : "John Atadana Vorodam",
        profession : "Technician",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 9,
        title : "Mr.",
        name : "Emmanuel K. Ampofo",
        profession : "Technician",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "AESL_Head Office",
        department : "Civil Engineering",
    },
    {   id: 10,
        title : "Mr.",
        name : "Jonathan Brown",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Jonathan Brown, .jpg",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 11,
        title : "Mr.",
        name : "Richard Alentis",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Richard Alentis.jpg",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 12,
        title : "Mr.",
        name : "Ernest Kofi Anyanumeh",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Ernest Kofi Anyanumeh.jpg",
        office : "AESL_Ho",
        department : "Civil Engineering",
    },
    {   id: 13,
        title : "Mr.",
        name : "Shaddrack K. Addo",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Shaddrack K. Addo.jpg",
        office : "AESL_Ho",
        department : "Architecture",
    },
    {   id: 14,
        title : "Mr.",
        name : "Victor Dogbatse",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Victor Dogbatse.jpg",
        office : "AESL_Ho",
        department : "Architecture",
    },
    {   id: 15,
        title : "Mr.",
        name : "Zakaria Abdul-Aziz",
        profession : "Accountant",
        thumbnail :"../../images/people/supportTeam/Mr. Zakaria.jpg",
        office : "AESL_Bolgatanga",
        department : "Administration",
    },
    {   id: 15,
        title : "Madam.",
        name : "Kyileyang Christiana",
        profession : "Secretary",
        thumbnail :"../../images/people/supportTeam/Madam Kyileyang Christiana.jpg",
        office : "AESL_Bolgatanga",
        department : "Administration",
    },
    {   id: 16,
        title : "Mr.",
        name : "Frank Kwabena Okyere",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Mr. Frank Kwabena Okyere.png",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 17,
        title : "Mr.",
        name : "Amadu Musah",
        profession : "Driver",
        thumbnail :"../../images/people/supportTeam/Mr. Amadu Musah.jpg",
        office : "AESL_Bolgatanga",
        department : "Transport",
    },
    
    {   id: 18,
        title : "Madam",
        name : "Claudia Peguu",
        profession : "Secretary",
        thumbnail :"../../images/people/supportTeam/Madam Claudia Peguu.jpg",
        office : "AESL_Bolgatanga",
        department : "Administration",
    },
    {   id: 18,
        title : "Mr.",
        name : "Philip Kudenu",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Mr. Philip Kudenu.jpg",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 19,
        title : "Mr.",
        name : "Abubakari Saaka",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Mr. Abubakari Saaka.jpg",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 20,
        title : "Mr.",
        name : "Inusah Bipuah",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Mr. Inusah Bipuah.jpg",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 21,
        title : "Mr.",
        name : " Aditanga Jacob",
        profession : "Technician",
        thumbnail :"../../images/people/supportTeam/Mr. Aditanga Jacob.jpg",
        office : "AESL_Bolgatanga",
        department : "Architecture",
    },
    {   id: 22,
        title : "Mr.",
        name : "Hannah Addo",
        profession : "Secretary",
        thumbnail :"../../images/people/supportTeam/Hannah Addo.jpg",
        office : "AESL_Ho",
        department : "Administration",
    },
    
    {   id: 23,
        title : "Mr.",
        name : "Nana Yaw Quainoo",
        profession : "Accountant",
        thumbnail :"../../images/people/supportTeam/Nana Yaw Quainoo.jpg",
        office : "AESL_Ho",
        department : "Administration",
    },
    {   id: 24,
        title : "Ms.",
        name : "Grace Mensah",
        profession : "Secretary",
        thumbnail :"../../images/people/supportTeam/Grace Mensah.jpg",
        office : "AESL_Ho",
        department : "Administration",
    },
    {   id: 25,
        title : "Mr.",
        name : "Joshua Gbedebu",
        profession : "Driver",
        thumbnail :"../../images/people/supportTeam/Joshua Gbedebu.jpg",
        office : "AESL_Ho",
        department : "Transport",
    },
    {   id: 26,
        title : "Mr.",
        name : "Divine Adiku",
        profession : "Driver",
        thumbnail :"../../images/people/supportTeam/Divine Adiku.jpg",
        office : "AESL_Ho",
        department : "Transport",
    },
    {   id: 27,
        title : "Mr.",
        name : "Samuel Dorgbetor",
        profession : "Security",
        thumbnail :"../../images/people/supportTeam/Samuel Dorgbetor.jpg",
        office : "AESL_Ho",
        department : "Administration",
    },
    {   id: 28,
        title : "Mr.",
        name : "Charles Dugble",
        profession : "Security",
        thumbnail :"../../images/people/supportTeam/Charles Dugble.jpg",
        office : "AESL_Ho",
        department : "Administration",
    },
    
];

export const servicePersonnel = [
    {   id: 1,
        title : "Title",
        name : "Full Naame",
        slug : "full-name",
        profession : "Profession",
        thumbnail :"../../images/placeholders/regular_images/avatar-01.png",
        office : "Office",
        department : "Department",
    },
];
