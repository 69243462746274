import AESLPageFooter from '../../components/organisms/footers/AESLPageFooter'
import publicationsBanner from '../../images/placeholders/landscape_Images/featured_grid.jpg'
import CategoryHeaderBanner from '../../components/molecules/banners/CategoryHeaderBanner'
import { PiBookBold } from 'react-icons/pi'
import { useEffect } from 'react'
import AESLPublicationsNav from '../../components/organisms/navs/AESL_PublicationsNav'
import tReport from '../../pages/publications/technicalReports/technical-report-template-17.jpg'
import aReport from '../../pages/publications/articles/Cover-Page-22_SRC.jpg'
import fReport from '../../pages/publications/financialReports/1131w-SBrY61aUKcQ.jpg'
import wpReport from '../../pages/publications/whitePapers/white-paper-template-32.jpg'
import rReport from '../../pages/publications/reviews/R1.jpg'
import PublicationsCard from '../../components/organisms/cards/PublicationsCard'
import CategoryOverview from '../../components/molecules/CategoryOverview'
const Index = () => {
    
    useEffect(() => {
        document.title = "AESL || Publications";
    }, []);
    
    return (
        <div id="PublicationsPage"
            className=""
        >
            {/* Page Header Start */}
            <header id = ""
                className="bg-yellow"
            >   
                <CategoryHeaderBanner 
                    image = {publicationsBanner}
                    title = 'publications'
                    Icon = {<PiBookBold />}
                />
                <AESLPublicationsNav />
            </header>
            {/* Page Header End */}

             {/* Page Main Start */}
          <main className=""
          >
              {/* Overview  */}
              <div id="ContentHeader"
                    className="grid gtc12 ggap1-00
                        mb2-00"
                >
                    <CategoryOverview
                        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam minima repudiandae, magnam tenetur corporis architecto maxime quis laudantium suscipit quam. Corrupti aliquam nulla dolor, optio odit voluptatem veniam et neque."
                        
                    />
                    
                </div>
                {/* Overview  */}
              {/* News Start */}
                  <div className="grid gtc12 ggap1-00
                      mv2-00 pa1-00 bg-black-10"
                  >
                    <article className="gc1s8 gr1s1 bg-white "
                    >
                        <PublicationsCard 
                            image={tReport}
                            title="Technical Reports"
                            overview = "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias ad animi eligendi cum doloremque, corrupti tempora. Aperiam, voluptatum earum tenetur amet cum voluptates ipsum? Enim rerum atque ex quaerat quidem consectetur maiores ea, quis mollitia, minus earum eos rem hic sapiente. Laudantium optio similique error blanditiis veniam vitae excepturi animi."
                            url= '/publications/technical-reports'
                        />
                    </article>
                        
                    <article className="gc5s8 gr2s1 bg-white"
                    >
                        <PublicationsCard 
                            image={fReport}
                            title="Financial Reports"
                            overview = "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias ad animi eligendi cum doloremque, corrupti tempora. Aperiam, voluptatum earum tenetur amet cum voluptates ipsum? Enim rerum atque ex quaerat quidem consectetur maiores ea, quis mollitia, minus earum eos rem hic sapiente. Laudantium optio similique error blanditiis veniam vitae excepturi animi."
                            url= '/publications/financial-reports'
                        />
                    </article>

                    <article className="gc5s8 gr4s1 ba bg-white pa1-00"
                    >
                        <PublicationsCard 
                            image={wpReport}
                            title="White papers"
                            overview = "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias ad animi eligendi cum doloremque, corrupti tempora. Aperiam, voluptatum earum tenetur amet cum voluptates ipsum? Enim rerum atque ex quaerat quidem consectetur maiores ea, quis mollitia, minus earum eos rem hic sapiente. Laudantium optio similique error blanditiis veniam vitae excepturi animi."
                            url= '/publications/white-papers'
                        />
                    </article>

                      <article className="gc1s8 gr3s1 ba bg-white pa1-00"
                      >
                          <PublicationsCard 
                            image={aReport}
                            title="Articles"
                            overview = "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias ad animi eligendi cum doloremque, corrupti tempora. Aperiam, voluptatum earum tenetur amet cum voluptates ipsum? Enim rerum atque ex quaerat quidem consectetur maiores ea, quis mollitia, minus earum eos rem hic sapiente. Laudantium optio similique error blanditiis veniam vitae excepturi animi."
                            url= '/publications/articles'
                        />
                      </article>

                      <article className="gc1s8 gr5s1 ba bg-white pa1-00"
                      >
                          <PublicationsCard 
                            image={rReport}
                            title="Reviews"
                            overview = "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias ad animi eligendi cum doloremque, corrupti tempora. Aperiam, voluptatum earum tenetur amet cum voluptates ipsum? Enim rerum atque ex quaerat quidem consectetur maiores ea, quis mollitia, minus earum eos rem hic sapiente. Laudantium optio similique error blanditiis veniam vitae excepturi animi."
                            url= '/publications/reviews'
                        />
                      </article>

                     
                  </div>
              {/* News End */}
          </main>
          {/* Page Main End */}

            {/* Page Footer Start */}
            <footer id = ""
                className=""
            >
                <AESLPageFooter 
                    pageTitle = 'Publications'
                />
            </footer>
            {/* Page Footer End */}
        </div>
    )
}

export default Index