import facebook from '../../../svgs/socialMedia/facebook.svg'
import twitter from '../../../svgs/socialMedia/twitter.svg'
import linkedin from '../../../svgs/socialMedia/linkedin.svg'
import youtube from '../../../svgs/socialMedia/youtube.svg'

const PeoplePortraitCard = ({
  thumbnail,
  title,
  name,
  profession,
  office,
  department,
}) => {
  return (
    <article
      id="people_supportTeam_1"
      className="
                flex flex-column items-center justify-center ba br0-50 b--black-10 shadow-5"
    >
      <figure className="mb0-50">
        <img
          src={thumbnail}
          alt=""
          className="pa0-50 h16-00 w16-00 cover"
        />
      </figure>
      <hgroup
        className="flex flex-column items-center justify-start
                    pa0-25
                    blue0
                    f1-00"
      >
        <h2 className="f1-00 b tracked-vtight">
          {title} {name}
        </h2>
        <p className="mb0-25 bb">~ {profession} ~</p>
        <h6>
          {office} - <span>{department}</span>
        </h6>
      </hgroup>
      <footer className="flex w-100 bg-green">
        <div className="flex w-100 items-center  justify-center">
          <ul className="flex justify-around items-center w-50 f0-75 pt0-50">
            <li>
              {/* <a
                href="/"
                className="flex justify-center items-center
                    white-90"
                target="_blank"
                rel="noreferrer"
              > */}
                <figure className="ba br-100">
                  <img src={twitter} className="w1-00 h1-00 pa0-25" alt="{}" />
                </figure>
              {/* </a> */}
            </li>
            <li>
              {/* <a
                href="/"
                className="flex justify-center items-center
                    white-90"
                target="_blank"
                rel="noreferrer"
              > */}
                <figure className="ba br-100">
                  <img src={facebook} className="w1-00 h1-00 pa0-25" alt="{}" />
                </figure>
              {/* </a> */}
            </li>
            <li>
              {/* <a
                href="/"
                className="flex justify-center items-center
                    white-90"
                target="_blank"
                rel="noreferrer"
              > */}
                <figure className="ba br-100">
                  <img src={linkedin} className="w1-00 h1-00 pa0-25" alt="{}" />
                </figure>
              {/* </a> */}
            </li>
          </ul>
        </div>
      </footer>
    </article>
  );
};

export default PeoplePortraitCard;
