import React from 'react'
import SubCategoryHeaderBanner from '../../../components/molecules/banners/SubCategoryHeaderBanner'
import AESLPublicationsNav from '../../../components/organisms/navs/AESL_PublicationsNav'
import AESLPageFooter from '../../../components/organisms/footers/AESLPageFooter'
import xxx from '../../../images/placeholders/landscape_Images/featured_grid.jpg'
const ArticlesIndex = () => {
    return (
        <div id="PublicationsPage"
            className=""
        >
            {/* Page Header Start */}
            <header id = ""
                className="bg-white"
            >   
                <SubCategoryHeaderBanner 
                    image = {xxx}
                    title = 'articles'
                />
                <AESLPublicationsNav />
            </header>
            {/* Page Header End */}

            {/* Page Main Start */}
            <main className="grid gtc12"
            >
                {/* News Start */}
                    <div className="gc1s10
                        pa1-00"
                    >
                        <article className="ba mv2-00"
                        >
                            <div className="grid gtc12 ggap1-00">
                                <div className="gc1s4">
                                <figure>
                                    <img src={xxx} alt="" 
                                    />
                                    <figcaption>

                                    </figcaption>
                                </figure>
                                </div>
                                <div className="gc5s8">
                                    <hgroup className="flex justify-around items-start flex-column"
                                    >
                                        <h2 className="
                                            w-100 h-100
                                            mt0-75"
                                        >
                                            Report Name
                                        </h2>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Published By:
                                        </h4>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Date Published:
                                        </h4>
                                    </hgroup>
                                </div>
                            </div>
                        </article>
                        <article className="ba mv2-00"
                        >
                            <div className="grid gtc12 ggap1-00">
                                <div className="gc1s4">
                                <figure>
                                    <img src={xxx} alt="" 
                                    />
                                    <figcaption>

                                    </figcaption>
                                </figure>
                                </div>
                                <div className="gc5s8">
                                    <hgroup className="flex justify-around items-start flex-column"
                                    >
                                        <h2 className="
                                            w-100 h-100
                                            mt0-75"
                                        >
                                            Report Name
                                        </h2>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Published By:
                                        </h4>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Date Published:
                                        </h4>
                                    </hgroup>
                                </div>
                            </div>
                        </article>
                        <article className="ba mv2-00"
                        >
                            <div className="grid gtc12 ggap1-00">
                                <div className="gc1s4">
                                <figure>
                                    <img src={xxx} alt="" 
                                    />
                                    <figcaption>

                                    </figcaption>
                                </figure>
                                </div>
                                <div className="gc5s8">
                                    <hgroup className="flex justify-around items-start flex-column"
                                    >
                                        <h2 className="
                                            w-100 h-100
                                            mt0-75"
                                        >
                                            Report Name
                                        </h2>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Published By:
                                        </h4>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Date Published:
                                        </h4>
                                    </hgroup>
                                </div>
                            </div>
                        </article>
                        <article className="ba mv2-00"
                        >
                            <div className="grid gtc12 ggap1-00">
                                <div className="gc1s4">
                                <figure>
                                    <img src={xxx} alt="" 
                                    />
                                    <figcaption>

                                    </figcaption>
                                </figure>
                                </div>
                                <div className="gc5s8">
                                    <hgroup className="flex justify-around items-start flex-column"
                                    >
                                        <h2 className="
                                            w-100 h-100
                                            mt0-75"
                                        >
                                            Report Name
                                        </h2>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Published By:
                                        </h4>
                                        <h4 className="
                                            w-100 h-100
                                            mt0-75 
                                            red"
                                        >
                                            Date Published:
                                        </h4>
                                    </hgroup>
                                </div>
                            </div>
                        </article>
                    </div>
                {/* News End */}
            </main>
            {/* Page Main End */}

            {/* Page Footer Start */}
            <footer id = ""
                className=""
            >
                <AESLPageFooter 
                    pageTitle = 'Publications'
                />
            </footer>
            {/* Page Footer End */}
        </div>
    )
}

export default ArticlesIndex
