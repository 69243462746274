import React from 'react'
import ProjectDetailCard from '../../../components/organisms/cards/ProjectDetailCard'
import xxx from '../../../images/placeholders/regular_images/sky.jpg'
import ProjectSectionFooter from '../../../components/organisms/footers/ProjectSectionFooter'

import {projects} from '../../../data/aeslProjectsInfo'

const ProjectConstruction = ({project}) => {
  return (
    <div className="grid gtc12 ggap1-00
                                ph1-00 mb3-00">
       {/* <!-- Project constuction Title --> */}
       <hgroup id="project_constuction Title"
            className="gc1s12"
        >
            <h2 className="mv1-00
                f2-00 f3-00-m"
            >
                PROJECT CONSTRUCTION
            </h2>     
        </hgroup>
        {/* <!-- Project constuction Title --> */}

        {/* <!-- Project constuction Description --> */}
        <article id="project_constuction_Description" 
            className="gr2s1 gc1s12 ggap2-00 flex w-100 tj"
        >
            <div className="f1-25 tj lh-copy">
            <div className="f1-25 tj lh-copy">
                <ul>
                    <li>{project.projectConstruction.construction1}</li>
                    <li>{project.projectConstruction.construction2}</li>
                    <li>{project.projectConstruction.construction3}</li>
                </ul>
            </div>
            </div> 
        </article>    
        {/* <!-- Project constuction Description --> */}

        {/* <!-- Project constuction gallery --> */}
        <div className="gc1s12 grid gtc12 ggap1-00">
            <article id="project_constuction_gallery_1" 
                className="gr1s2 gc1s6
                    flex items-center justify-center
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[0]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_2" 
                className="gr2s2 gc7s6
                    flex items-center justify-center
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[1]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_3" 
                className="gr3s2 gc1s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[3]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_4" 
                className="gr4s2 gc7s6
                    flex items-center justify-center
                    min-vh-50
                    relative " 
            >
                <ProjectDetailCard image={project.constructionGallery[2]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>


            <article id="project_constuction_gallery_5" 
                className="gr5s2 gc1s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[4]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_6" 
                className="gr6s2 gc7s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[5]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_7" 
                className="gr7s2 gc1s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[6]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_8" 
                className="gr8s2 gc7s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[7]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_9" 
                className="gr9s2 gc1s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[8]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            {/* <article id="project_constuction_gallery_10" 
                className="gr10s2 gc7s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[9]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_11" 
                className="gr11s2 gc1s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[10]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article>

            <article id="project_constuction_gallery_12" 
                className="gr12s1 gc7s6
                    flex items-center justify-center
                    h-100
                    relative"
            >
                <ProjectDetailCard image={project.constructionGallery[11]} 
                    title="Concept Image One" 
                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                />
            </article> */}
        </div>

        <footer id="project_Concept_gallery_5" 
            className="gr9s1 gc1s12
                items-center justify-center
                h-100
                relative"
        >
            <ProjectSectionFooter />
        </footer>
        {/* <!-- Project Construction gallery --> */}
    </div>
  )
}

export default ProjectConstruction
