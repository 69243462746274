import React from 'react'
import SubCategoryHeaderBanner from '../../../components/molecules/banners/SubCategoryHeaderBanner'
import AESLPublicationsNav from '../../../components/organisms/navs/AESL_PublicationsNav'
import xxx from '../../../images/placeholders/landscape_Images/featured_grid.jpg'
import AESLPageFooter from '../../../components/organisms/footers/AESLPageFooter'


const WhitePapersIndex = () => {
 
    return (
      <div id="PublicationsPage"
          className=""
      >
          {/* Page Header Start */}
          <header id = ""
              className="bg-white"
          >   
              <SubCategoryHeaderBanner 
                  image = {xxx}
                  title = 'White Papers'
              />
              <AESLPublicationsNav />
          </header>
          {/* Page Header End */}

          {/* Page Main Start */}
          <main className="grid gtc12"
          >
              {/* News Start */}
                  <div className="gc1s10
                      ph1-00 mv2-00 pa1-00 bg-black-10"
                  >
                      <article className="ba bg-white pa1-00 mb2-00"
                      >
                          <div className="grid gtc12 ggap1-00">
                              <div className="gc1s4">
                              <figure>
                                  <img src={xxx} alt="" 
                                  />
                                  <figcaption>

                                  </figcaption>
                              </figure>
                              </div>
                              <div className="gc5s8">
                                  <hgroup className="flex justify-around items-start flex-column"
                                  >
                                      <h3 className="
                                          w-100 h-100
                                          mt0-75"
                                      >
                                          Buildin Green White Paper, 2022.
                                      </h3>
                                      <h4 className="
                                          w-100 h-100
                                          mt0-75 
                                          red"
                                      >
                                          Published By: Architecture Department
                                      </h4>
                                      <h4 className="
                                          w-100 h-100
                                          mv0-75 
                                          red"
                                      >
                                          Date Published: May 2023.
                                      </h4>
                                  </hgroup>
                                  <button className="pa0-50 br0-25 ttu bg-blue0 b--none white-90">
                                    view
                                </button>
                              </div>
                          </div>
                      </article>
                      <article className="ba bg-white pa1-00 mb2-00"
                      >
                          <div className="grid gtc12 ggap1-00">
                              <div className="gc1s4">
                              <figure>
                                  <img src={xxx} alt="" 
                                  />
                                  <figcaption>

                                  </figcaption>
                              </figure>
                              </div>
                              <div className="gc5s8">
                                  <hgroup className="flex justify-around items-start flex-column"
                                  >
                                      <h3 className="
                                          w-100 h-100
                                          mt0-75"
                                      >
                                          Social Housing White Paper.
                                      </h3>
                                      <h4 className="
                                          w-100 h-100
                                          mt0-75 
                                          red"
                                      >
                                          Published By: Financial Manager
                                      </h4>
                                      <h4 className="
                                          w-100 h-100
                                          mv0-75 
                                          red"
                                      >
                                          Date Published: May 2023.
                                      </h4>
                                  </hgroup>
                                  <button className="pa0-50 br0-25 ttu bg-blue0 b--none white-90">
                                    view
                                </button>
                              </div>
                          </div>
                      </article>
                  </div>
              {/* News End */}
          </main>
          {/* Page Main End */}

          {/* Page Footer Start */}
          <footer id = ""
              className=""
          >
              <AESLPageFooter 
                  pageTitle = 'Publications/Financial Reports'
              />
          </footer>
          {/* Page Footer End */}
      </div>
  )
}

export default WhitePapersIndex
