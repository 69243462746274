import React from 'react'
import map from '../../../images/projects/_partials/samplemap.jpg'
import ProjectSectionFooter from '../../../components/organisms/footers/ProjectSectionFooter'
import {projects} from '../../../data/aeslProjectsInfo'
const ProjectData = ({project}) => {
    return (
        <div className="grid gtc12 ggap1-00
            ph1-00 mb3-00"
        >
            {/* <!-- Project Data Title --> */}
            <hgroup id="project_data Title"
                className="gc1s12 gr1s1">
                <h2 className="mv1-00
                    f2-00 f3-00-m"
                >
                    PROJECT DATA
                </h2>     
            </hgroup>
            {/* <!-- Project Data Title --> */}
            <div className="gc1s12 gr2s1 
                grid gtc12 ggap0-50"
            >
                <div id="projectData--projectCoordinator" 
                    className="gc1s4 gr1s1 pa0-50 bg-blue2"
                >
                    <hgroup className="mb1-00 white-90">
                        <h2 className="mb0-50 f0-75">
                            Project Director
                        </h2>
                        <p className="mb0-25">
                            {project.projectCoordinator}
                        </p>
                    </hgroup>
                </div>

                <div id="projectData--projectLeads" 
                    className="gc1s4 gr2s1 bg-blue1 pa0-50"
                >
                    <hgroup className="mb0-00 white-90">
                        <h2 className="mb0-50 f0-75">
                            Project Leads
                        </h2>
                        <ul className="mb0-25">
                            <li>{project.projectLeads.architectural}</li> 
                            <li>{project.projectLeads.structural}</li>
                            <li>{project.projectLeads.installations}</li>
                        </ul>
                    </hgroup>
                </div>

                <div id="projectData--Data" 
                    className="gc1s12"
                >
                    <div className="flex justify-between ggap0-50"
                    >
                        <div className="w-100 ba pa0-50 br0-25 b--black-10"
                        >
                            <h5 className="mb0-50">Other Project Team Members</h5>
                            <ul className="flex flex-column w-100"
                            >
                                <li>{project.otherTeamMembers[0]}</li>
                                <li>{project.otherTeamMembers[1]}</li>
                                <li>{project.otherTeamMembers[2]}</li>
                                <li>{project.otherTeamMembers[3]}</li>
                                <li>{project.otherTeamMembers[4]}</li>
                                <li>{project.otherTeamMembers[5]}</li>
                                <li>{project.otherTeamMembers[6]}</li>
                                <li>{project.otherTeamMembers[7]}</li>
                                <li>{project.otherTeamMembers[8]}</li>
                                <li>{project.otherTeamMembers[9]}</li>
                                <li>{project.otherTeamMembers[10]}</li>
                            </ul>
                        </div>
                        <div id=""
                            className="w-100 ba pa0-50 br0-25 b--black-10"
                        >
                            <hgroup id="project_summary_1" 
                                className=""
                            >
                                    <h5 className="mb0-50">
                                    Start Date
                                    </h5>
                                    <p className="mb0-25"> 
                                        {project.dates.start} 
                                    </p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                    Completion Date
                                    </h5>
                                    <p className="mb0-25">
                                    {project.dates.completed} 
                                    </p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Location
                                    </h5>
                                    <p className="mb0-25">{project.location}</p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Site Area
                                    </h5>
                                    <p className="mb0-25">{project.siteArea} acres</p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Gross Floor Area
                                    </h5>
                                    <p className="mb0-25">{project.grossFloorArea }m<sup>2</sup></p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Floors
                                    </h5>
                                    <p className="mb0-25">{project.numberOfFloors }</p>
                            </hgroup>
                        </div>
                        
                        <div id=""
                            className="w-100 ba pa0-50 br0-25 b--black-10">
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        General Contractor
                                    </h5>
                                    <p className="mb0-25">{project.contractors.general}</p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Electrical Contractors
                                    </h5>
                                    <p className="mb0-25">{project.contractors.electrical}</p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Mechanical Contractors
                                    </h5>
                                    <p className="mb0-25">{project.contractors.mechanical}</p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Plumbing Contractors
                                    </h5>
                                    <p className="mb0-25">{project.contractors.plumbing}</p>
                            </hgroup>
                            <hgroup id="project_summary_1" 
                                className="">
                                    <h5 className="mb0-50">
                                        Facade Contractors
                                    </h5>
                                    <p className="mb0-25">{project.contractors.facade}</p>
                            </hgroup>
                        </div>
                    </div>
                </div>

                <div className="gc5s8 gr1s2
                        w-100 h0-00 pb-100
                        bg-gold"
                >
                    <img src = {map}
                        className="w-100"
                        alt="tttt"
                    />
                </div>

                <div className="gc1s4 gr3s1
                        w-100 h0-00 pb-100
                        bg-blue0 white-90">
                    <h4 className="pa0-50">Job Sheets</h4>
                    <p className="pa0-50 mb0-00">English</p>
                    <p className="pa0-50 mb0-00">French</p>
                </div>
                
                <div className="gc9s4 gr3s1
                    w-100 h0-00 pb-100
                    bg-black-40 white-90 of-y-scroll"
                >
                    <div className="h-100 ph0-50">
                    <h4 className="pa0-50">News Item 1</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quisquam, perspiciatis .
                        </p>
                    <h4 className="pa0-50">News Item 2</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quisquam, perspiciatis .
                        </p>
                    </div>
                </div>

                <div className="gc5s4 gr3s1
                    w-100 h-100 pa0-50
                    bg-blue1 white-90"
                >
                    <h4 className="mb0-50">Awards</h4>
                    <div className="f0-75">
                        <div className="flex">
                            <div className="mr0-50">
                                {project.awards.award1.date}
                            </div>
                                
                            <div>
                                <div>
                                <span className="black-60">Award Name:</span> {project.awards.award1.name}
                                </div>
                                <div>
                                    <span className="black-60">Awarded by:</span> {project.awards.award1.institution}
                                </div>
                                <a href={project.awards.award1.url}
                                    className="black-60"
                                >
                                    {project.awards.award1.url}
                                </a>
                            </div> 
                               
                        </div>
                        <div className="flex">
                            <div className="mr0-50">{project.awards.award2.date}</div>
                            <div>
                                <div>
                                <span className="black-60">Awarded Name:</span> {project.awards.award2.name}
                                </div>
                                <div>
                                    {project.awards.award2.institution}
                                </div>
                                <a href={project.awards.award2.url}
                                    className="black-60"
                                >
                                    {project.awards.award2.url}
                                </a>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mr0-50">{project.awards.award3.date}</div>
                            <div>
                                <div>
                                <span className="black-60">Awarded Name:</span> {project.awards.award3.name}
                                </div>
                                <div>
                                    {project.awards.award3.institution}
                                </div>
                                <a href={project.awards.award3.url}
                                    className="black-60"
                                >
                                    {project.awards.award3.url}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="gr3s1 gc1s12">
                <ProjectSectionFooter />
            </footer>
        </div>
    )
}

export default ProjectData
