import { Link } from 'react-router-dom'


const PublicationsCard = (
    {
    image,
    title,
    overview,
    url
    }
) => {
    return (
        <div className="grid gtc12 ggap1-0 h-100">
            <figure className="gc1s4"
            >
            <img src={image} 
                className=""
                alt="" 
            />
            </figure>
            <div className="gc5s8 ph1-00">
                <hgroup className="flex justify-around items-start flex-column mb2-00"
                >
                    <h2 className="
                        w-100 h-100
                        mt0-75"
                    >
                        {title}
                    </h2>
                    <p className="
                        w-100 h-100
                        mb0-75 
                        blue0 f1-25"
                    >
                        {overview}
                    </p>
                </hgroup>
                <Link to={url} className="pa0-50 mb0-00 br0-25 ttu bg-blue0 b--none white-90">
                Read More..
            </Link>
            </div>
        </div>
    )
}

export default PublicationsCard
