import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import SubCategoryHeaderBanner from "../../../components/molecules/banners/SubCategoryHeaderBanner"
import AESLPageFooter from "../../../components/organisms/footers/AESLPageFooter"
import AESLProjectDetailsNav from "../../../components/organisms/navs/AESLProjectDetailsNav"
// import projectsBanner from '../../../images/projects/civic_and_culture/culture/5_0.jpg'
// import ProjectOverview from "./ProjectOverview"
import ProjectGallery from "./ProjectGallery"
// import ProjectConcept from "./ProjectConcept"
// import ProjectDesign from "./ProjectDesign"
import ProjectConstruction from "./ProjectConstruction"
import ProjectData from "./ProjectData"
import RelatedProjects from "./RelatedProjects"
import {projects} from '../../../data/aeslProjectsInfo'

const ProjectDetails = (props) => {
    // Project Details Parameters
    const {slug,category,subcategory} = useParams();
    
    // State Variables
    const [project, setProject] = useState('Default Project');
    const [isLoading, setIsLoading] = useState(true)
    

    /* 
    Side Effects 
    */
    // Document Title  
    useEffect(() => {
        document.title=`AESL || projects/${category}/${subcategory}/${slug}`
    }, [category, subcategory, slug]);
    
    useEffect(() => {
        setIsLoading(true)
        try {
            const newProject = projects.find(
                (project) => (project.slug).toString()===slug
            );
            // console.log(newProject)
            setProject(newProject)
            setIsLoading(false)
        } catch (error) {
            console.error('Failed to fetch project data:', error);
            setIsLoading(false)
        }
        
    }, [slug]);


    if (isLoading) {
        return <h1>Loading...</h1>;
    }

    if (!project) {
        return <div>No project available.</div>;
    }

    return (
        <div>
            <article id = ""
                className = ""
            >
                {/* Page Header Start */}
                <header id=""
                    className="bg-yellow pb1-00"
                >
                    <SubCategoryHeaderBanner 
                        image = {`../../${project.thumbnail}`}
                        title ={project.name}
                        subTitle = {project.location}
                    />
                    <AESLProjectDetailsNav project={project}/>
                </header>
                {/* Page Header End */}
                
                {/* Page Main Start */}
                <div id="l_3:1_Layout_ProjectDetails" 
                    className="grid gtc4 bg-black-10"
                >    
                    {/* <!--Page Main Start --> */}
                        <main id="lc_gc1s3_Main_ProjectDetils"
                            className="gc1s4 gc1s3-l
                                min-vh-100"
                        >   
                            {/* <!-- Project Details--> */}
                            <article id="projectDetails"
                                className="min-vh-100 w-100 
                                blue0"
                            >
                                {/* <!-- Projects Overview --> */}
                                <section id="projectDetails--Overview"
                                    className=""
                                >
                                    <div 
                                        className="gr1s1 gc2s10
                                            pb2-00 mh1-00
                                            blue0 bb"
                                    >
                                        <hgroup id="Project_Overview_1"
                                            className="f1-25 f1-50-m "
                                        >
                                        <h2 className="mv1-00
                                            f2-00 f3-00-m ttu"
                                        >
                                            Project Overview
                                        </h2>
                                        </hgroup> 
                                        <div className="f1-25 tj lh-copy"
                                        >
                                            <ul>
                                                {project.overview.map((ov, index) => {
                                                            return(
                                                                <li key={index} >{ov}</li>
                                                            )
                                                        }
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- Projects Overview --> */}

                                {/* <!-- Projects gallery --> */}
                                <section id="projectDetails--Gallery"
                                    className=""
                                >   
                                    <ProjectGallery project={project} />
                                </section>
                                {/* <!-- Projects gallery --> */}
                                
                                {/* <!-- Projects Concept --> */}
                                <section id="projectDetails--Concept"
                                    className=""
                                >
                                    {/* <ProjectConcept project={project} /> */}
                                </section>
                                {/* <!-- Projects Concept -->  */}

                                {/* <!-- Projects Design --> */}
                                <section id="projectDetails--Design"
                                    className=""
                                >
                                    {/* <ProjectDesign project={project} /> */}
                                </section>
                                {/* <!-- Projects Design -->  */}

                                {/* <!-- Projects Construction --> */}
                                <section id="projectDetails--Construction"
                                    className=""
                                >
                                <ProjectConstruction project={project} />
                                </section>
                                {/* <!-- Projects Construction -->  */}

                                {/* <!-- Project Data Start --> */}
                                <section id="projectDetails--Data"
                                    className=""
                                >
                                    <ProjectData project={project} />
                                </section>
                                {/* <!-- Project Data End --> */}
                                
                                {/* <!-- Related Projects Start --> */}
                                <section id="projectDetails--RelatedProjects"
                                    className=""
                                >
                                    <RelatedProjects project={project} />
                                </section>
                                {/* <!-- Related Projects End --> */}
                                {/* <!-- Page Footer Start--> */}
                                <footer id="pageFooter"
                                    className="mb1-00"
                                > 
                                    <AESLPageFooter pageTitle = {`projects - ${project.name}`}/>
                                </footer>
                                {/* <!-- Page Footer End --> */}
                            </article>
                                {/* <!-- Project Data -->  */}
                        </main>
                    {/* <!-- Page Main End --> */}

                    {/* <!-- Page Aside Start --> */}
                        <aside id="lc_gc4s1_Aside_ProjectDetils" 
                            className="gc4s1-l"
                        >
                            <nav id=""
                                className="dn flex-l flex-column-l justify-start-l
                                    min-vh-100 w-100 
                                    
                                    sticky top-0"
                            >
                                <hgroup className="ttu f1-00 pb1-00 bg-white pv1-00 ma0-50">
                                    <Link to="/projects"
                                        className="blue0 ml1-50"    
                                    >Back to Projects</Link>
                                </hgroup>
                                <ul className="bl bw3 b--blue0 pa1-00 f0-75 blue0 bg-white-20 ma0-50"
                                >
                                    <h2 className="pb0-50 bb ttc">On this page</h2>
                                    <li className="mb1-00 i">
                                        <a href="#projectDetails--Overview"
                                            className="blue0"
                                        >Project Overview</a>
                                    </li>
                                    <li className="mb1-00 i">
                                        <a href="#projectDetails--Gallery"
                                                    className="blue0"
                                                >Project Gallery</a>
                                    </li>
                                    <li className="mb1-00 i">
                                        <a href="#projectDetails--Concept"
                                                    className="blue0"
                                                >Project Concept</a>
                                    </li>
                                    <li className="mb1-00 i">
                                        <a href="#projectDetails--Design"
                                                    className="blue0"
                                                >Project Design</a>
                                    </li>
                                    <li className="mb1-00 i">
                                        <a href="#projectDetails--Construction"
                                                    className="blue0"
                                                >Project Construction</a>
                                    </li>
                                    <li className="mb1-00 i">
                                        <a href="#projectDetails--Data"
                                                    className="blue0"
                                                >Project Data</a>
                                    </li>
                                    <li className=" i">
                                        <a href="#projectDetails--RelatedProjects"
                                                    className="blue0"
                                                >Related Projects</a>
                                    </li>
                                </ul>
                            </nav>
                        </aside>
                    {/* <!-- Page Aside End --> */}
                </div>
                {/* Page Main End */} 

                {/* Page Footer Start */}
                {/* <footer id="" 
                    className=""
                >
                    <AESLPageFooter
                        
                    />
                </footer> */}
                {/* Page Footer End */}
            </article>
        </div>
    )
}

export default ProjectDetails
