import projectsBanner from "../../images/projects/00.jpeg";
import SectionFooter from "../../components/organisms/footers/SectionFooter";
import CategoryHeaderBanner from "../../components/molecules/banners/CategoryHeaderBanner";
import AESLProjectsNav from "../../components/organisms/navs/AESL_ProjectsCategoriesNav";
import AESLPageFooter from "../../components/organisms/footers/AESLPageFooter";
import { Link } from "react-router-dom";

import { projects } from "../../data/aeslProjectsInfo";

// Data Fetch
const fetchProjects = () => {
  return projects;
};

const ProjectsList = () => {
  const projectsData = fetchProjects();
  return (
    <article id="" className="">
      {/* Page Header Start */}
      <header id="" className="bg-yellow">
        <CategoryHeaderBanner image={projectsBanner} title="projects List" />
        <AESLProjectsNav />
      </header>
      {/* Page Header End */}

      {/* Page Main Start */}
      <main className="bg-white grid gtc12">
        <header id="functions--overview" className="gr1s1 gc2s10 f1-25">
          <hgroup id="" className="mv2-00">
            <p
              className=" blue0
                                lh-copy tj"
            >
              These are the Projects of The Architectural and Engineering
              Services Limited (AESL).
            </p>
            <p className="lh-copy tj"></p>
          </hgroup>
        </header>

        {/* <!-- Projects List--> */}
        <div id="projects" className="gc1s12 ph1-00 mt2-00 tracked-tight">
          <div
            className="flex 
                            fw4
                            bb"
          >
            <span className="w6-00 pb0-50 mb0-50 ">Start Date</span>
            <span className="w14-00 pb0-50 mb0-50 ">Category</span>
            <span className="w8-00 pb0-50 mb0-50">SubCategory</span>
            <span className="w22-00 pb0-50 mb0-50">Project Name</span>
            <span className="w20-00 pb0-50 mb0-50">Client Name</span>
            <span className="w10-00 pb0-50 mb0-50">Location</span>
          </div>
          <ul className="mb1-00">
           
          {projectsData &&
                        projectsData.map((project) => {
                        return (
                        <Link key={project.id}
                            className="dib w-100"
                            to={`/projects/${project.category.url}/${project.category.subCategory}/${project.slug}`} 
                        >
                            <article id="" className="flex items-center justify-center">
                            <hgroup
                                className="flex justify-start 
                                                        w-100
                                                        blue0 bb b--black-10"
                            >
                                <p className="w6-00 pv0-50 mb0-00 pb0-00">{project.dates.start}</p>
                                <p className="w14-00 pv0-50 mb0-00 pb0-00">{project.category.name}</p>
                                <p className="w8-00 pv0-50 mb0-00 pb0-00">{project.category.subCategory} </p>
                                <p className="w22-00 pv0-50 mb0-00 pb0-00">{project.name}</p>
                                <p className="w20-00 pv0-50 mb0-00 pb0-00">{project.client}</p>
                                <p className="w10-00 pv0-50 mb0-00 pb0-00">{project.location}</p>
                            </hgroup>
                            </article>
                        </Link>
                        );
                        })
                    }
            <li
              className="flex items-center justify-between
                                w-100 f1-00"
            >
              
            </li>

          </ul>

          <article
            id="misc_gold"
            className="gr3s2 gc1s3
                            flex items-center justify-center
                            bg-gold h4-00 w8-00
                        "
          ></article>

          <article
            id="misc_blue"
            className="gr3s2 gc10s3
                            flex items-center justify-center
                            bg-black-90 h6-00 w2-00
                            "
          ></article>
        </div>
        <footer id="projectsPage__Footer" className="gc1s12 mv1-00">
          <SectionFooter sectionTitle="Projects List" />
        </footer>
        {/* <!-- Projects List --> */}
        {/* <!-- Page Main --> */}
      </main>
      {/* Page Main End */}

      {/* Page Footer Start */}
      <footer id="" className="">
        <AESLPageFooter pageTitle="Projects List" />
      </footer>
      {/* Page Footer End */}
    </article>
  );
};

export default ProjectsList;
