import React from 'react'
import AESLLogo from '../../../images/_partials/logos/aesl_logo.png'
const AESL_PageFooter = (props) => {
    
    const {pageTitle} = props

    return (
        <div className="flex-m justify-between items-center
            pa1-00 mb1-00 mh1-00
            bg-blue0 gold"
        >
            <div className="fle justify-center items-center f1-50"
            >
                <div className="flex f1-25">
                    <img src={AESLLogo} 
                        alt="AESL Logo" 
                        className="w2-00 h2-00 mr0-50"
                    />
                    <div>AESL || <span className="">{pageTitle}</span></div>
                </div>
                <div><span className=""></span></div>
            </div>
            <div className="tc">
                Consulting Architects, <br /> Engineers and Surveyors
            </div>
        </div> 
    )
}

export default AESL_PageFooter