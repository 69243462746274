import React from 'react'

const HODDetails = () => {
  return (
    <div>
        HOD Details
    </div>
  )
}

export default HODDetails
