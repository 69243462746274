import React from 'react'
import { Link } from 'react-router-dom'
const ProjectCard = (
        {   
            name,
            slug, 
            client,
            location, 
            thumbnail,
            description,
            category,
        }
    ) =>
        {
            return (
                <li 
                >
                    <article id="" 
                        className="flex flex-column justify-start items-start
                        bg-blue0 pa0-50 shadow-yellow
                        br0-50 white-90"
                    >
                    <header 
                        className="w-100 "
                    >
                        <hgroup className="flex flex-column justify-between
                            bg-white-10 yellow br0-25 pa0-25"
                        >
                            <h2 className="h4-50
                                mb0-25
                                b 
                                f1-25"
                            >
                                {name} 
                                
                            </h2>
                            <p className="mb0-25">
                                    <span> {location}</span>
                            </p>
                        </hgroup>    
                        <div className="pv0-50 h4-00 tt"
                        >
                            <p className="pb0-25 mb0-25 bb b b--yellow white-90">Client: </p>
                            <p className="ttc mb0-25">{client}</p> 
                        </div> 
                           
                        
                    </header>

                    <main className="flex flex-column justify-between
                            mv1-00 w-100"
                    >
                        <figure className="w-100"
                        >
                            <img src={thumbnail}
                                alt={`An image of ${name}`}
                                className="cover z5 w-100 h12-00 h16-00-xl"
                            />
                            <figcaption id=""
                                className="flex flex-column justify-between 
                                    h12-00 w-100
                                    mt1-00 
                                    f1-00 lh-copy tj"
                            >
                                <p className="h10-00 i"
                                >
                                    {`${description.toString().substring(0,196)} ...`}
                                </p>
                                <div>
                                    <Link to={`/projects/${category.url}/${category.subCategory}/${slug}`}
                                        className="dib b ttu pv0-25 ph0-50 br0-25 bg-yellow"
                                    >
                                        read more...
                                    </Link>
                                </div>
                            </figcaption>
                        </figure>

                    </main>

                    <footer 
                        className="w-100 f0-75 pa0-25 bg-white-10 ba br0-50 b--white-20"
                    > 
                        <div className="flex justify-between">
                            <h5 className="pa0-50 bg-white b blue0 br0-25"
                            >
                                {category.name}
                            </h5>
                            <small className="pa0-50 b ba b--yellow br0-25 yellow"
                            >
                                {category.subCategory}
                            </small>
                        </div>
                    </footer>
                    </article>
                </li>
            )
        }

export default ProjectCard