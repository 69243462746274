import React from 'react'

const SubCategoryHeaderBanner = (props) => {
    const {image, title, subTitle} = props

    return (
        <section id = "princeples_pageHeader"
            className="grid gtc12"
        >
            <figure className="gc1s12"
            >
                <img src={image}
                    alt=""
                    className="w-100 vh-50 cover"
                />
            </figure>
            <hgroup className="w-100 blue0 pa1-00 gc1s12"
            >
                <h2 className="
                    mb0-25 f1-75 f2-50-m ttu tl tracked-tight"
                >
                    {title}
                </h2>
                <span className="f1-50">
                    {subTitle}
                </span>
            </hgroup>
        </section>
    )
}

export default SubCategoryHeaderBanner