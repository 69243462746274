// Images
import projectsBanner from "../../images/projects/00.jpeg";
import proj01 from "../../images/projects/civic_and_culture/civic/PoliceHeadquaters/1_0.jpg";
import proj02 from "../../images/projects/civic_and_culture/civic/PoliceHeadquaters/1_1.jpg";

// Compoments
import CategoryHeaderBanner from "../../components/molecules/banners/CategoryHeaderBanner";
import AESLPageFooter from "../../components/organisms/footers/AESLPageFooter";
import AESLProjectsNav from "../../components/organisms/navs/AESL_ProjectsCategoriesNav";
import ProjectCard from "../../components/organisms/cards/ProjectCard";
import SectionFooter from "../../components/organisms/footers/SectionFooter";
// import { useParams } from 'react-router-dom'
import { projects, projectCategories } from "../../data/aeslProjectsInfo";
import { useState } from "react";
import { useParams } from "react-router-dom";

const ProjectCategoryDetail = (props) => {
  
  const {} = (props);

  const { category } = useParams();
  console.log(category);

  return (
    <article id="" className="">
      {/* Page Header Start */}
      <header id="" className="bg-yellow">
        <CategoryHeaderBanner
          image={category}
          // icon = {}
          title={projectCategories.name}
        />
        <AESLProjectsNav />
      </header>
      {/* Page Header End */}

      {/* Page Main Start */}
      <main className="bg-white grid gtc12">
        {/* <!-- Overview --> */}
        <header id="overview" className="gc1s12 grid gtc12 ggap1-00">
          {/* <!-- Project_categories_overview -->  */}
          <div
            id="overview"
            className="gr1s1 gc2s10 
                            w-100 pt4-00
                            blue0 bb"
          >
            <hgroup id="Project_Overview_1" className="f1-50">
              <p className="mb0-00 lh-copy tracked tj">{category.overview}</p>
            </hgroup>
          </div>
        </header>
        {/* <!-- Overview --> */}

        {/* <!-- Projects --> */}
        <div id="projects" className="gc1s12 w-100 mt2-00 mb1-00 ph1-00">
          {/* {% for projectSubCategory in projectSubCategories %} */}

          <ul className="mb2-00">
            <li className="">
              <header className="bg-blue3 grid gtc12">
                <hgroup className="gc1s8 gr1s1 blue0 f1-25 tj pa1-00 pr2-00">
                  <h2>project SubCategory One</h2>
                  <p className="mb0-00">
                    project SubCategory description Lorem ipsum dolor, sit amet
                    consectetur adipisicing elit. Fugit facilis magni numquam ad
                    illum asperiores possimus optio vero architecto culpa
                    excepturi animi odit ipsa eligendi praesentium, facere
                    temporibus accusantium quisquam?
                  </p>
                </hgroup>
                <img
                  src={proj01}
                  className="gc9s4 gr1s1 cover w-100 h16-00"
                  alt="errte"
                />
              </header>
              <ul
                className="grid gtc2-s gtc3-m gtc4-xl ggap1-00 
                                justify-start
                                mt2-00 mb1-00"
              >
                {projects &&
                  projects.map((project) => {
                    return <ProjectCard key={project.id} {...project} />;
                  })}
              </ul>
              <footer className="flex pa1-00 bg-blue0 gold i">
                <div className="mr0-50"> project Category Name</div> ||
                <div className="ml0-50"> project SubCategory One</div>
              </footer>
            </li>
          </ul>
          {/* {% endfor %} */}
        </div>
        {/* <!-- Projects --> */}

        {/* <!--Projects page Footer --> */}
        <footer id="projectsPage__Footer" className="gc1s12 mb1-00">
          <SectionFooter sectionTitle="Projects" />
        </footer>
        {/* <!--Projects page Footer --> */}
      </main>
      {/* Page Main End */}

      {/* Page Footer Start */}
      <footer id="" className="">
        <AESLPageFooter pageTitle="Projects" />
      </footer>
      {/* Page Footer End */}
    </article>
  );
};

export default ProjectCategoryDetail;
