
// Components
import SectionHeader from '../../components/organisms/headers/SectionHeader'
import SectionFooter from '../../components/organisms/footers/SectionFooter'
import ProjectCategoryCard from '../../components/organisms/cards/ProjectCategoryCard'

// Images
import projectsbgImage from '../../images/index/sectionsBgImages/projectCategories_01.jpg'
import civicAndCulture from '../../images/index/AESL_ProjectCategories/civic_and_culture_01.jpg'
import education from '../../images/index/AESL_ProjectCategories/education.jpg'
import health from '../../images/index/AESL_ProjectCategories/health.jpg'
import office_retail_and_mixed_use from '../../images/index/AESL_ProjectCategories/office_and_mixed_use.jpg'
import industrial_and_infrastructure from '../../images/index/AESL_ProjectCategories/industrial_and_infrastructure.jpeg'
import hospitality from '../../images/index/AESL_ProjectCategories/hospitality.png'
import residential from '../../images/index/AESL_ProjectCategories/residential.jpg'
import landscape_planning_and_survey from '../../images/index/AESL_ProjectCategories/landscape_planning_and_survey.jpg'
import sports_and_leisure from '../../images/index/AESL_ProjectCategories/sports_and_leisure.jpg'
import { Link } from 'react-router-dom'

const ProjectCategories = () => {
    return (
        <section id="HomePageMain--ProjectCategories"
                    className="bg-black-20 mb1-00"            
                >
                    <SectionHeader 
                        bgImage= {projectsbgImage}
                        title = 'Projects' 
                        overview = 'Our impressive portfolio of diverse and notable projects is characterized by innovative and sustainable design solutions that prioritize functionality, aesthetics, and the well-being of occupants. Our projects also cuts across all the tribal cultures and the socioeconomic sectors of the Ghanaian economy.'
                    />
                    <article id="ProjectCategories__content"
                        className="flex items-center justify-center
                            pa2-00 pa4-00-m"
                    >
                        <div id="4x4_Grid_projectCategories__content"
                            className="grid gtc12 ggap1-00 
                                pa1-00
                                bg-blue0"
                        >
                            <div id="projectCategories__civicAndCulture"
                                className="gr1s1 gc1s12
                                    gc1s6-s
                                    gr1s3-m gc1s3-m 
                                    grid items-center justify-center
                                    white-90"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/civic-and-culture'
                                    image={civicAndCulture}
                                    caption='Civic and Culture'
                                />
                            </div>
                            
                            <div id="projectCategories__education"
                                className="gr2s1 gc1s12 
                                    gr1s1-s gc7s6-s 
                                    gr1s3-m gc4s3-m 
                                    grid items-center justify-center
                                    white-90"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/education'
                                    image={education}
                                    caption='Education'
                                />
                            </div>

                            <div id="projectCategories__health" 
                                className="gr3s1 gc1s12 
                                    gr2s1-s gc1s6-s 
                                    gr1s3-m gc7s3-m
                                    grid items-center justify-center"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/health'
                                    image={health}
                                    caption='Health'
                                />
                            </div> 
                                
                            <div id="projectCategories__officeRetailAndMixedUse" 
                                className="gr4s1 gc1s12
                                    gr2s1-s gc7s6-s
                                    gr1s3-m gc10s3-m
                                    grid items-center justify-center
                                    r"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/office-retail-and-mixed-use'
                                    image={office_retail_and_mixed_use}
                                    caption='Office Retail and Mixed-Use'
                                />
                            </div>
            
                            <div id="projectCategories__industrialAndInfrastructure" 
                                className="gr5s1 gc1s12
                                    gr3s1-s gc1s6-s
                                    gr4s3-m gc1s3-m
                                    grid items-center justify-center"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/industrial-and-retail'
                                    image={industrial_and_infrastructure}
                                    caption='Industrial and Infrastructure'
                                />
                            </div>
                            
                            <div id="projectCategories__hospitality" 
                                className="gr6s1 gc1s12
                                    gr3s1-s gc7s6-s
                                    gr4s3-m  gc10s3-m
                                    grid items-center justify-center" 
                            >
                                <ProjectCategoryCard 
                                    // url='projects/hospitality'
                                    image={hospitality}
                                    caption='Hospitality'
                                />
                            </div>
                            
                            <div id="projectCategories__blank" 
                                className="gr7s1 gc1s12
                                    gr4s1-s gc1s6-s
                                    gr7s3-m gc4s3-m
                                    grid items-center justify-center ba br0-50 b--white-90"
                            >
                                
                            </div>
                            
                            <div id="projectCategories__residential" 
                                className="gr8s1 gc1s12
                                    gr4s1-s gc7s6-s
                                    gr7s3-m gc10s3-m
                                    grid items-center justify-center"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/residential'
                                    image={residential}
                                    caption='residential'
                                />
                            </div>
                            
                            <div id="projectCategories__landscapeAndMmasterplanning" 
                                className="gr9s1 gc1s12
                                    gr5s1-s gc1s6-s
                                    gr7s3-m gc7s3-m
                                    grid items-center justify-center"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/landscape-and-master-planning'
                                    image={landscape_planning_and_survey}
                                    caption='Landscape, Planning and Survey'
                                />
                            </div>
                            
                            <div id="projectCategories__sportsAndLeisure" 
                                className="gr10s1 gc1s12
                                    gr5s1-s gc7s6-s
                                    gr7s3-m gc1s3-m
                                    grid items-center justify-center"
                            >
                                <ProjectCategoryCard 
                                    // url='projects/sports-and-leisure'
                                    image={sports_and_leisure}
                                    caption='Sports and Leisure'
                                />
                            </div>
                        
                            <div id="projectCategories__brief" 
                                className="gr11s1 gc1s12
                                    gr6s1-s
                                    gr4s3-m gc4s6-m
                                    grid"
                            >
                                <hgroup id = ""
                                    className="gr1s1 gc1s1 flex flex-column items-center justify-center
                                        h-100 w-100 bg-white
                                        ph1-00
                                        blue0 f0-75
                                        br0-50
                                        tc
                                        
                                            ">
                                    <h1 className = "blue0 f2-00 mv1-00">
                                        Lots of projects and still counting
                                    </h1>    
                                    <Link to="projects"
                                        className="blue0 ba br0-25 dib pa0-50 mb0-50 f1-00"
                                    >
                                        AESL Projects
                                    </Link>   
                                </hgroup> 
                            </div>
                        </div>
                    </article>
                    <SectionFooter 
                        sectionTitle = 'Projects'
                    />
                </section>
    )
}

export default ProjectCategories