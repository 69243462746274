import React from 'react'
import { Link } from 'react-router-dom'

const AESL_PublicationsNav = () => {
    return (
        <div id="pageNav"
    className="grid gtc12 gc1s12 ggap1-00
        justify-between
        white-90 pv1-00 ph1-00 bg-blue0"
>
    <article id="publications_nav1" 
        className="gr1s1 gc1s1
            w10-00 h0-00 pb-100 
            f1-00
            ba br0-50 shadow-yellow"
    >
        <ul id=""
            className="pa0-50"
        >
            <li className="">
                <Link  to="/publications"
                    className="white-90 hover-gold:focus hover-gold:hover" 
                >Publications</Link> 
            </li>     
        </ul> 
    </article>
    <article id="publications_nav1" 
        className="gr1s1 gc2s1
            w10-00 h0-00 pb-100 
            f1-00
            ba br0-50 shadow-yellow"
    >
        <ul id=""
            className="pa0-50"
        >
            <li className="">
                <Link  to="/publications/financial-reports"
                    className="white-90 hover-gold:focus hover-gold:hover" 
                >Financial Reports</Link> 
            </li>     
            <li className="">
                <Link  to="/publications/technical-reports"
                    className="white-90"
                >Technical Reports</Link>
            </li>
            <li className="mb0-50">
                <Link  to="/publications/articles"
                    className="white-90" 
                >Articles</Link> 
            </li>   
            <li className="mb0-50 ">
                <Link  to="/publications/reviews"
                    className="white-90" 
                >Reviews</Link>
            </li>     
        </ul> 
    </article>
    
    <article id="publications_nav2" 
        className="gr2s1 gr1s1-m gc2s1 gc3s1-m
            w10-00 h0-00 pb-100
            f1-00 
            ba br0-50 shadow-yellow"
    >
        
    </article>

    <article id="publications_nav3" 
        className="gr1s1-m gc1s1 gr2s1 gc12s1-m
            w10-00 h0-00 pb-100 
            bg-white blue0 br0-50 ba
            f1-00 shadow-yellow"
    >
            <ul className="pa0-50"
            >
                <li className=" mb0-50"
                >
                    <Link  to="/publications/white-papers"
                        className="blue0" 
                    >
                        White Papers
                    </Link>
                </li>
            </ul>
    </article>
</div>
    )
}

export default AESL_PublicationsNav