import { useEffect } from "react";

// Components
import CategoryHeaderBanner from "../../components/molecules/banners/CategoryHeaderBanner";
import CategoryOverview from "../../components/molecules/CategoryOverview";
import AESLProjectsNav from "../../components/organisms/navs/AESL_ProjectsCategoriesNav";
import ProjectCard from "../../components/organisms/cards/ProjectCard";
import SectionFooter from "../../components/organisms/footers/SectionFooter";
import AESLPageFooter from "../../components/organisms/footers/AESLPageFooter";

// Icons
import { GrProjects } from "react-icons/gr";

// Images
import projectsBanner from "../../images/projects/00.jpeg";

// Data
import { projects } from "../../data/aeslProjectsInfo";

// Data Fetch
const fetchProjects = () => {
  return projects;
};

// Default Function
const ProjectsIndex = () => {
    const projectsData = fetchProjects();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    useEffect(() => {
        document.title = "AESL || Projects";
    }, []);

    return (
      <article id="pageContent" className="">
            {/* Page Header Start */}
            <header id="pageHeader" className="bg-yellow">
                <CategoryHeaderBanner
                    image={projectsBanner}
                    icon={<GrProjects />}
                    title="projects"
                />
                <AESLProjectsNav />
            </header>
            {/* Page Header End */}

            {/* Page Main Start */}
            <main id="pageMain" className="mb1-00">
                {/* <!-- Overview --> */}
                <div id="overview" className="grid gtc12 ggap1-00">
                    <CategoryOverview description="Our unmatched and exceptional projects, which seamlessly blend architectural and engineering expertise, contribute to the enhancement of the built environment. Our portfolio encompasses a diverse range of ventures and projects, including residential, commercial, educational, healthcare, and infrastructure development. Our innovative professionals pay meticulous attention to details, making projects stand out and meeting the clients’ requirements." />
                </div>
                {/* <!-- Overview --> */}

                {/* <!-- Projects --> */}
                <div id="projects" className="mb1-00 pa1-00 white-90 bg-black-10">
                    <ul
                    className="grid gtc2-s gtc3-m ggap1-00 
                        justify-start
                        "
                    >
                    {projectsData &&
                        projectsData.map((project) => {
                        return (<ProjectCard key={project.id} {...project} />);
                        })
                    }
                    </ul>
                </div>
                {/* <!-- Projects --> */}
                {/* <!-- Projects Footer --> */}
                <SectionFooter sectionTitle="Projects" />
                {/* <!-- Projects Footer --> */}
            </main>
            {/* Page Main End */}

            {/* Page Footer Start */}
            <footer id="pageFooter" className="">
                <AESLPageFooter pageTitle="Projects" />
            </footer>
            {/* Page Footer End */}
      </article>
    );
};

export default ProjectsIndex;
