import ProjectDetailCard from '../../../components/organisms/cards/ProjectDetailCard'
import ProjectSectionFooter from '../../../components/organisms/footers/ProjectSectionFooter'

const ProjectGallery = ({project}) => {
    
    return (
        project &&  <div className="
                        w-100
                        ph1-00 mb2-00"
                    >
                        <hgroup id=""
                            className="gc1s12"
                        >
                            <h2 className="mv1-00
                                f2-00 f3-00-m"
                            >
                                PROJECT GALLERY
                            </h2>
                        </hgroup>
                        <main className="grid gtc12 ggap1-00">
                            <article id="project_gallery_1" 
                                className="gr1s1 gc1s9
                                    flex items-center justify-center
                                    relative"
                            >
                                <ProjectDetailCard 
                                    image={`${project.gallery[0]}`}
                                    title="Main Entrance" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_2" 
                                className="gr2s1 gc4s9
                                    flex items-center justify-center
                                    relative"
                            >
                                <ProjectDetailCard 
                                    image={`${project.gallery[1]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_3" 
                                className="gr3s1 gc1s9
                                    flex items-center justify-center
                                    relative"
                            >
                                <ProjectDetailCard 
                                    image={`${project.gallery[2]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_4" 
                                className="gr4s1 gc4s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[3]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_5" 
                                className="gr5s1 gc1s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[4]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_6" 
                                className="gr6s1 gc4s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[5]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_7" 
                                className="gr7s1 gc1s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[6]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>
                            
                            <article id="project_gallery_8" 
                                className="gr8s1 gc4s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[7]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_9" 
                                className="gr9s1 gc1s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[8]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_10" 
                                className="gr10s1 gc4s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[9]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_11" 
                                className="gr11s1 gc1s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[10]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>

                            <article id="project_gallery_12" 
                                className="gr12s1 gc4s9
                                    flex items-center justify-center
                                    relative"
                            >
                            <ProjectDetailCard 
                                    image={`${project.gallery[11]}`}
                                    title="Concept Image One" 
                                    description = "Repellat, voluptate, amet soluta quas magnam accusamus nesciunt voluptates aut quo quas nemo?"
                                />
                            </article>
                        </main>
                        <footer id="project_Concept_gallery_5" 
                            className="gr5s1 gc1s12
                                items-center justify-center
                                h-100
                                relative"
                        >
                            <ProjectSectionFooter />
                        </footer>
                    </div>
    )
}

export default ProjectGallery
